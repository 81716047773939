import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "@components/Hero/Hero";

export const OrdersHero = () => {
	const props = {
		title: 'Simplify Order Management in LIMS for Easier Tracking ',
		description: 'Streamline your order processes, from the moment a request is made to the item’s receipt, all within a centralized biopharma LIMS environment. ',
		image: (
			<StaticImage
				loading="eager"
				src="../../_assets/orders_product.png"
				alt="Genemod Orders Hero Image"
			/>
		)
	}

	return <Hero {...props} tabletTextAlignment="center" />
};
