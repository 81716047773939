import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import { PageLayout, TypographyV2 } from "@components/index";
import Margins from "@components/Margins/Margins";
import { StaticImage } from "gatsby-plugin-image";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import React from "react";
import * as styles from "./orders.module.scss";
import { OrdersHero } from "./_components/_OrdersHero/_OrdersHero";

const cards: FeatureCardProps[] = [
	{
		title: "Easily Manage and Submit Orders",
		image: (
			<StaticImage
				src={"./_assets/orders_img_3.png"}
				alt="Easily Manage and Submit Orders"
			/>
		),
		list: [
			"Get a comprehensive view of all order requests and their current status on one dashboard.",
			"Keep things clear with high-level overviews, so you always know where each item stands.",
			"Speed up the order submission process with user-friendly tools, making approvals and processing a breeze.",
		],
	},
	{
		title: "Collaborate for In-depth Order Tracking",
		image: (
			<StaticImage
				src={"./_assets/orders_img_2.png"}
				alt="Collaborate for In-depth Order Tracking"
			/>
		),
		list: [
			"Foster team collaboration when it comes to incoming and outgoing orders, improving communication and accuracy.",
			"A detailed order log helps you keep track of progress, including comments, receipts, and other essential attachments.",
			"Maintain transparency and end-to-end documentation for every order so your operations can go off without a hitch.",
		],
		orientation: "right",
	},
	{
		title: "Find Orders Quickly and Customize Workflows",
		image: (
			<StaticImage
				src={"./_assets/orders_img_3.png"}
				alt="Find Orders Quickly and Customize Workflows"
			/>
		),
		list: [
			"Use keyword-based searches to instantly locate specific orders and optimize response times.",
			"Take advantage of a flexible LIMS system that lets you adjust workflow stages to fit custom order statuses.",
			"Tailor the platform to your unique operational needs so that it aligns perfectly with your internal processes.",
		],
	},
];

const Orders = () => {
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "orders" }}>
			<Margins>
				<OrdersHero />
				<FeatureCardLayout className={styles.cards}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index} />;
					})}
				</FeatureCardLayout>
				<JoinInstitutionsV2
					outerContainerClassName={styles.thousandOfInsittutions}
				/>
			</Margins>
		</PageLayout>
	);
};

export default Orders;
